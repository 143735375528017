<template>
  <div class="card card-custom">
    <div class="card-body p-0">
      <!--begin::Invoice-->
      <button
        class="btn btn-success mt-5 mr-5 btn-back"
        id="btn-back"
        style="float: right"
        @click="goBack"
      >
        Go Back
      </button>
      <div
        class="row justify-content-center pt-8 px-8 pt-md-27 px-md-0"
        id="printMe"
      >
        <div class="col-md-10" v-if="invoiceDetails != null">
          <!-- begin: Invoice header-->
          <div
            class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row"
          >
            <h1 class="display-4 font-weight-boldest mb-10">INVOICE</h1>

            <div class="d-flex flex-column align-items-md-end px-0">
              <!--begin::Logo-->
              <a href="#" class="mb-5 max-w-200px text-dark"
                ><h5>Appointment No: {{ invoiceDetails.appointment.id }}</h5></a
              >
              <!--end::Logo-->
              <span
                class="d-flex flex-column align-items-md-end font-weight-bold text-muted"
              >
                <span>{{ invoiceDetails.service_provider.name }}, </span>
                <span> {{ invoiceDetails.service_provider.full_address }}</span>
                <!-- <span>Mississippi 96522</span> -->
              </span>
            </div>
          </div>

          <!--end: Invoice header-->
          <!--begin: Invoice body-->
          <div class="row border-bottom pb-10">
            <div class="col-md-9 py-md-10 pr-md-10">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th
                        class="pt-1 pb-9 pl-0 font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Description
                      </th>
                      <th
                        class="pt-1 pb-9 text-right font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Qty
                      </th>
                      <th
                        class="pt-1 pb-9 text-right font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Rate
                      </th>
                      <th
                        class="pt-1 pb-9 text-right font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Tax
                      </th>
                      <th
                        class="pt-1 pb-9 text-right pr-0 font-weight-bolder text-muted font-size-lg text-uppercase"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      class="font-weight-bolder border-bottom-0 font-size-lg"
                      v-for="(item, index) in invoiceDetails.invoice_detail"
                      :key="index"
                    >
                      <td
                        v-if="item.title && item.title != ''"
                        class="border-top-0 pl-0 py-4 d-flex align-items-center"
                      >
                        {{ item.title }}
                      </td>
                      <td
                        v-else-if="
                          index > 0 &&
                            item.is_additional == false &&
                            item.rate < 0
                        "
                        class="border-top-0 pl-0 py-4 d-flex align-items-center"
                      >
                        Promocode Discount
                      </td>
                      <td
                        v-else-if="index > 0 && item.is_additional == false"
                        class="border-top-0 pl-0 py-4 d-flex align-items-center"
                      >
                        {{
                          invoiceDetails.appointment.archival_period ==
                          "Archival"
                            ? "Archival"
                            : invoiceDetails.appointment.archival_period +
                              "(Archival)"
                        }}
                      </td>
                      <td
                        v-else
                        class="border-top-0 pl-0 py-4 d-flex align-items-center"
                      >
                        {{
                          item.is_additional
                            ? "Additional"
                            : invoiceDetails.service_name
                        }}
                      </td>
                      <td
                        class="border-top-0 text-right py-4"
                        v-if="
                          index > 0 &&
                            item.is_additional == false &&
                            item.rate < 0
                        "
                      ></td>
                      <td class="border-top-0 text-right py-4" v-else>
                        {{ item.qty }}
                      </td>
                      <td
                        class="border-top-0 text-right py-4"
                        v-if="
                          index > 0 &&
                            item.is_additional == false &&
                            item.rate < 0
                        "
                      ></td>
                      <td class="border-top-0 text-right py-4" v-else>
                        ${{ item.rate | toFixedTwoDigit }}
                      </td>
                      <td
                        class="border-top-0 text-right py-4"
                        v-if="
                          index > 0 &&
                            item.is_additional == false &&
                            item.rate < 0
                        "
                      ></td>
                      <td
                        class="border-top-0 pr-0 py-4 font-size-h6 font-weight-boldest text-right"
                        v-else
                      >
                        ${{ item.tax_amount | toFixedTwoDigit }}
                      </td>
                      <td
                        class="border-top-0 pr-0 py-4 font-size-h6 font-weight-boldest text-right"
                        v-if="item.is_additional"
                      >
                        ${{
                          (parseInt(item.qty) * parseFloat(item.rate) +
                            parseFloat(item.tax_amount))
                            | toFixedTwoDigit
                        }}
                      </td>
                      <td
                        class="border-top-0 pr-0 py-4 font-size-h6 font-weight-boldest text-right"
                        v-else-if="
                          index > 0 &&
                            item.is_additional == false &&
                            item.rate < 0
                        "
                      >
                        ( ${{
                          (parseInt(item.qty) * parseFloat(item.rate) * -1 +
                            parseFloat(item.tax_amount))
                            | toFixedTwoDigit
                        }}
                        )
                      </td>
                      <td
                        class="border-top-0 pr-0 py-4 font-size-h6 font-weight-boldest text-right"
                        v-else
                      >
                        ${{
                          (parseFloat(item.rate) * parseInt(item.qty) +
                            parseFloat(item.tax_amount))
                            | toFixedTwoDigit
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <hr />
              </div>
              <!-- <div class="border-bottom w-100 mt-7 mb-13"></div> -->
              <div class="d-flex flex-column flex-md-row">
                <div
                  class="d-flex flex-column mb-10 mb-md-0"
                  v-if="
                    invoiceDetails.payment_type == 'C' &&
                      invoiceDetails.payment_status == 'Refund'
                  "
                >
                  <div class="font-weight-bold font-size-h6 mb-3">
                    Payment Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Payment Type: &ensp;Credit Card</span
                    >
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-6">
                    <span class="font-weight-bold mr-15"
                      >Payment Staus: &ensp;Refund</span
                    >
                  </div>
                </div>
                <div
                  class="d-flex flex-column mb-10 mb-md-0"
                  v-if="
                    invoiceDetails.payment_type == 'C' &&
                      invoiceDetails.payment_status != 'Refund'
                  "
                >
                  <div class="font-weight-bold font-size-h6 mb-3">
                    Payment Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Payment Type: &ensp;Credit Card</span
                    >
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-6">
                    <span class="font-weight-bold mr-15"
                      >Payment Staus: &ensp;Paid</span
                    >
                  </div>
                  <!-- <div class="font-weight-bold font-size-h6 mb-3">
                    CARD Detail
                  </div> -->
                  <!-- <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15">Card Holdername:</span>
                    <span class="text-right">Barclays UK</span>
                  </div> -->
                  <!-- <div class="d-Find our location onflex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Card Number: &ensp;
                      <span v-for="(item, index) in 12" :key="index">
                        &#8226;
                      </span>
                      {{ invoiceDetails.card_number }}
                    </span>
                  </div> -->

                  <!-- <div class="d-flex justify-content-between font-size-lg">
                    <span class="font-weight-bold mr-15"
                      >Card Type: &ensp;{{ invoiceDetails.card_type }}</span
                    >
                  </div> -->
                </div>

                <div
                  class="d-flex flex-column mb-10 mb-md-0 mr-5"
                  v-if="invoiceDetails.payment_type == 'O'"
                >
                  <div class="font-weight-bold font-size-h6 mb-3">
                    Payment Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Payment Type: &ensp;Onsite payment</span
                    >
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-6">
                    <span class="font-weight-bold mr-15"
                      >Payment Staus: &ensp;Collect Payment</span
                    >
                  </div>
                  <div class="font-weight-bold font-size-h6 mb-3">
                    ON-SITE PAYMENT
                  </div>
                </div>
                <div
                  class="d-flex flex-column mb-10 mb-md-0 mr-5"
                  v-if="invoiceDetails.payment_type == 'CP'"
                >
                  <div class="font-weight-bold font-size-h6 mb-3">
                    Payment Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Payment Type: &ensp;Company payment</span
                    >
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-6">
                    <span class="font-weight-bold mr-15"
                      >Payment Staus: &ensp;Bill to Company</span
                    >
                  </div>
                  <div class="font-weight-bold font-size-h6 mb-3">
                    COMPANY Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15">Name:</span>
                    <span class="text-right">{{
                      invoiceDetails.appointment.location_job.client_name
                    }}</span>
                  </div>

                  <div class="d-flex justify-content-between font-size-lg">
                    <span class="font-weight-bold mr-15">Address:</span>
                    <span class="text-right">{{
                      invoiceDetails.appointment.location_job.client_address
                    }}</span>
                  </div>
                </div>

                <div
                  class="d-flex flex-column mb-10 mb-md-0"
                  v-if="invoiceDetails.payment_type == 'A'"
                >
                  <div class="font-weight-bold font-size-h6 mb-3">
                    Payment Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Payment Type: &ensp;Company</span
                    >
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-6">
                    <span class="font-weight-bold mr-15"
                      >Payment Staus: &ensp;Bill to Company</span
                    >
                  </div>
                  <div class="font-weight-bold font-size-h6 mb-3">
                    COMPANY Detail
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15">Code:</span>
                    <span class="text-right">{{
                      invoiceDetails.appointment.abbreviation_code
                        .abbreviation_code
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15">Name:</span>
                    <span class="text-right">{{
                      invoiceDetails.appointment.abbreviation_code.company_name
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between font-size-lg">
                    <span class="font-weight-bold mr-15">Address:</span>
                    <span class="text-right">{{
                      invoiceDetails.appointment.abbreviation_code.address
                    }}</span>
                  </div>
                </div>

                <!-- <div
                  class="d-flex flex-column mb-10 mb-md-0 ml-20"
                  style="border-left:1px solid #ccc;padding-left:10px"
                >
                  <div class="font-weight-bold font-size-h6 mb-3">
                    Applicant Details
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15"
                      >Appointment Number:</span
                    >
                    <span class="text-left">{{
                      invoiceDetails.appointment.id
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15">Fullname:</span>
                    <span class="text-left">{{
                      invoiceDetails.applicants.fname +
                        (invoiceDetails.applicants.mname != ""
                          ? " " + invoiceDetails.applicants.mname + " "
                          : " ") +
                        invoiceDetails.applicants.lname
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between font-size-lg mb-3">
                    <span class="font-weight-bold mr-15">Address:</span>
                    <span class="text-left">{{
                      invoiceDetails.applicants.address1 +
                        (invoiceDetails.applicants.address2 != ""
                          ? ", " + invoiceDetails.applicants.address2 + " "
                          : ", ") +
                        invoiceDetails.applicants.city +
                        ", " +
                        invoiceDetails.applicants.zipcode
                    }}</span>
                  </div>
                  <div class="d-flex justify-content-between font-size-lg">
                    <span class="font-weight-bold mr-15">Gender:</span>
                    <span class="text-left">{{
                      invoiceDetails.applicants.gender
                    }}</span>
                  </div>
                </div> -->
              </div>
              <hr v-if="invoiceDetails.appointment_details.length > 0" />
              <table
                class="table"
                v-if="invoiceDetails.appointment_details.length > 0"
              >
                <thead>
                  <tr>
                    <th
                      class="pt-1 pb-9 pl-0 font-weight-bolder text-muted font-size-lg text-uppercase"
                    >
                      Appointments
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="font-weight-bolder border-bottom-0 font-size-lg"
                    v-for="(item, index) in invoiceDetails.appointment_details"
                    :key="index"
                  >
                    <td
                      class="border-top-0 pl-0 py-4 d-flex align-items-center"
                    >
                      {{ item }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              class="col-md-3 border-left-md pl-md-10 py-md-10 text-right move-up-side-info"
            >
              <!--begin::Total Amount-->
              <div class="font-size-h4 font-weight-bolder text-muted mb-3">
                TOTAL AMOUNT
              </div>
              <div class="font-size-h1 font-weight-boldest">
                ${{ invoiceDetails.total | toFixedTwoDigit }}
              </div>
              <div class="text-muted font-weight-bold mb-16">
                Taxes included
              </div>
              <!--end::Total Amount-->
              <div class="border-bottom w-100 mb-16"></div>

              <!--begin::Invoice To-->
              <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                INVOICE TO.
              </div>
              <div class="font-size-lg font-weight-bold mb-10">
                {{
                  invoiceDetails.applicants.fname +
                    (invoiceDetails.applicants.mname != ""
                      ? " " + invoiceDetails.applicants.mname + " "
                      : " ") +
                    invoiceDetails.applicants.lname
                }}
                <br />{{
                  invoiceDetails.applicants.address1 +
                    (invoiceDetails.applicants.address2 != ""
                      ? ", " + invoiceDetails.applicants.address2 + " "
                      : ", ") +
                    invoiceDetails.applicants.city +
                    ", " +
                    invoiceDetails.applicants.zipcode
                }}
              </div>
              <!--end::Invoice To-->
              <!--begin::Invoice No-->
              <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                INVOICE NO.
              </div>
              <div class="font-size-lg font-weight-bold mb-10">
                {{ invoiceDetails.invoice_no }}
              </div>
              <!--end::Invoice No-->
              <!--begin::Invoice Date-->
              <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                DATE
              </div>
              <div class="font-size-lg font-weight-bold">
                {{ invoiceDetails.created_at | dateFormat }}
              </div>
              <div class="text-dark-50 font-size-lg font-weight-bold mb-3">
                Updated Date
              </div>
              <div class="font-size-lg font-weight-bold">
                {{ invoiceDetails.updated_at | dateFormatTime }}
              </div>
              <!--end::Invoice Date-->
            </div>
          </div>
          <!--end: Invoice body-->
        </div>
      </div>
      <!-- begin: Invoice action-->
      <div
        class="row justify-content-center py-8 px-8 py-md-28 px-md-0"
        id="printpagebutton"
      >
        <div class="col-md-10">
          <div class="d-flex font-size-sm flex-wrap">
            <button
              type="button"
              class="btn btn-primary font-weight-bolder py-4 mr-3 mr-sm-14 my-1"
              @click="printInvoice()"
            >
              Print Invoice
            </button>
          </div>
        </div>
      </div>
      <!-- end: Invoice action-->
      <!--end::Invoice-->
    </div>
  </div>
</template>

<script>
import { FETCH_INVOICE_DETAILS } from "@/core/services/store/actions.type";
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import moment from "moment";
export default {
  filters: {
    dateFormat(date) {
      return moment(date).format("DD MMM, YYYY");
    },
    dateFormatTime(date) {
      return moment(date).format("DD MMM,  HH:mm:ss");
    },
    toFixedTwoDigit(amount) {
      return parseFloat(amount).toFixed(2);
    }
  },
  data() {
    return {
      invoiceDetails: null,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Invoice Details",
          route: ""
        }
      ]
    };
  },
  mounted() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getInvoiceDetails();
  },

  methods: {
    //   printMe
    printInvoice() {
      var printButton = document.getElementById("printpagebutton");
      var kt_subheader = document.getElementById("kt_subheader");
      var kt_header = document.getElementById("kt_header");
      var kt_copyright_footer = document.getElementById("kt_copyright_footer");
      var kt_aside_parent = document.getElementById("kt_aside_parent");
      kt_header.classList.add("kt_header_toggle");
      var back_btn = document.getElementById("btn-back");
      printButton.style.visibility = "hidden";
      back_btn.style.visibility = "hidden";
      kt_subheader.style.visibility = "hidden";
      kt_header.style.visibility = "hidden";
      kt_copyright_footer.style.visibility = "hidden";
      kt_aside_parent.style.visibility = "hidden";
      document.body.classList.add("aside-minimize");
      window.print();
      kt_copyright_footer.style.visibility = "visible";
      printButton.style.visibility = "visible";
      kt_subheader.style.visibility = "visible";
      kt_header.style.visibility = "visible";
      kt_aside_parent.style.visibility = "visible";
      kt_header.classList.remove("kt_header_toggle");
      document.body.classList.remove("aside-minimize");
      back_btn.style.visibility = "visible";
    },
    goBack() {
      this.$router.go(-1);
    },
    getInvoiceDetails() {
      this.$store
        .dispatch(FETCH_INVOICE_DETAILS, {
          token: this.$route.params.token
        })
        .then(res => {
          this.invoiceDetails = res.data;
        });
    }
  }
};
</script>
